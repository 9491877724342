import './ItemApp.scss';
import moment from "moment";
import {
    BrowserRouter as Router,
    Link,
    Route,
    useHistory
} from "react-router-dom";
function ItemApp(props) {
    const history = useHistory();
    const timeAgo = (time) => {
        return "Added " + moment(time).fromNow();
    }

    return (
        <div className="item-app-homepage">
            <div className="info-item">
                <div className="item">
                    <div className="image-item">
                        <a href={"/app/" + props.value.detail.app_id}><img src={props.value.detail ? props.value.detail.app_icon : ""}></img></a>
                    </div>
                    <div className="text-item">
                        <a href={"/app/" + props.value.detail.app_id}>{props.value.detail ? props.value.detail.name : ""}</a>
                        <div>{props.value.detail && props.value.detail.partner ? <span>by <a href={"/developer/" + props.value.detail.partner.id}>{props.value.detail.partner.name}</a></span> : ""}</div>
                    </div>
                </div>
            </div>
            {props.type == "New Apps" &&
                <div className="text-description">
                    {timeAgo(props.value.created_at)}
                </div>
            }
            {props.type == "Top movers" &&
                <div className="text-description">
                    +{props.value.move_pos} positions
                </div>
            }
            {props.type == "Most reviewed" &&
                <div className="text-description">
                    +{props.value.review_count} reviews
                </div>
            }
            {/* <div className="text-description">
                {props.value.created_at}
            </div> */}
        </div>
    )
}
export default ItemApp;