const URL_API = process.env.REACT_APP_API;
const DOMAIN = process.env.REACT_APP_DOMAIN;

function getCategories() {
  return `${URL_API}cat`;
}
function getKeys() {
  return `${URL_API}keyword`;
}
function getApps() {
  return `${URL_API}app`;
}
function getHome() {
  return `${URL_API}home`;
}
function getDevelopers() {
  return `${URL_API}partner`;
}
function getTopNewApps() {
  return `${URL_API}top-new-apps`;
}

function getTopMovers() {
  return `${URL_API}top-movers`;
}
function getTopReviews() {
  return `${URL_API}top-reviewed`;
}
function searchData() {
  return `${URL_API}search`;
}
function createKeyword() {
  return `${URL_API}add_keyword`;
}
function deleteKeyword() {
  return `${URL_API}del_keyword`;
}
function reloadKeyword() {
  return `${URL_API}fetch_keyword`;
}
function getProfile() {
  return `${URL_API}profile`;
}
function urlLogin() {
  return `${URL_API}google_login?redirect_url=${DOMAIN}google_login_callback`;
}
function changeKeywordInChart() {
  return `${URL_API}save_keyword_in_chart`;
}
function saveKeywordPriority() {
  return `${URL_API}save_keyword_priority`;
}
function addCompetitor() {
  return `${URL_API}add_competitor`;
}
function deleteCompetitor() {
  return `${URL_API}del_competitor`;
}
function getCompetitor() {
  return `${URL_API}get_competitor`;
}

const Api = {
  login: URL_API + "login",
  getCategories: getCategories,
  getKeys: getKeys,
  getApps: getApps,
  getHome: getHome,
  getDevelopers: getDevelopers,
  getConversationCategory: (id) => `${URL_API}cat/${id}`,
  getDetailApp: (id) => `${URL_API}app/${id}`,
  getDetailAppHost: (id, host_id) => `${URL_API}app/${id}/${host_id}`,
  getDetailAppRange: (id, fromDate, toDate) => `${URL_API}app/${id}?start_date=${fromDate}&end_date=${toDate}`,
  getDetailAppHostRange: (id, host_id, fromDate, toDate) => `${URL_API}app/${id}/${host_id}?start_date=${fromDate}&end_date=${toDate}`,
  getDetailDeveloper: (id) => `${URL_API}partner/${id}`,
  getConversationKey: (id) => `${URL_API}keyword/${id}`,
  searchData: searchData,
  getTopNewApps: getTopNewApps,
  getTopMovers: getTopMovers,
  getTopReviews: getTopReviews,
  createKeyword: createKeyword,
  deleteKeyword: deleteKeyword,
  reloadKeyword: reloadKeyword,
  urlLogin: urlLogin,
  loginGoogle: (state, code) => `${URL_API}google_login_callback?redirect_url=${DOMAIN}google_login_callback&state=${state}&code=${code}`,
  changePassword: `${URL_API}/change_password`,
  getProfile: getProfile,
  gaLogin: (id) => `${URL_API}ga_login?redirect_url=${DOMAIN}ga_callback&app_id=${id}`,
  gaDisconnect: (id) => `${URL_API}ga_disconnect?redirect_url=${DOMAIN}ga_callback&app_id=${id}`,
  gaSyncGoogle: (state, code) => `${URL_API}ga_callback?redirect_url=${DOMAIN}ga_callback&state=${state}&code=${code}`,
  changeKeywordInChart: changeKeywordInChart,
  saveKeywordPriority: saveKeywordPriority,
  addCompetitor: addCompetitor,
  deleteCompetitor: deleteCompetitor,
  getCompetitor: getCompetitor,

  refreshToken: () =>
    `${URL_API}refresh`,    
};
export default Api;
