import { Modal, Input, Form, Button, message, Spin } from 'antd';
import React, { useRef, useState } from "react";
import { FetchApi } from '../../api/FetchAPI';
import "./ModalCompetitor.scss";
import {
    LoadingOutlined,
} from "@ant-design/icons";
const { Search } = Input;

function ModalCompetitor(props) {
    const page = useRef(1);

    const [listResult, setListResult] = useState();
    const [itemActive, setItemActive] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleOk = () => {
        props.disableModal();
    };

    const handleCancel = () => {
        props.disableModal();
    };

    const saveCompetitor = async () => {
        const appId = props.appId;
        if (appId) {
            if (itemActive) {
                const result = await FetchApi.addCompetitor(appId, itemActive.app_id);
                if (result.code === 0) {
                    props.dataTabNew(itemActive)
                    props.disableModal();
                    setItemActive(null);
                    message.success("Add competitor success");
                } else {
                    message.error("Add competitor error");
                }
            } else {
                message.warn("Please select a app");
                return;
            }

        }
    };

    const onSearch = async (value) => {
        setIsLoading(true);
        const result = await FetchApi.searchData(value, page.current, 100);
        if (result && result.code === 0) {
            if (result.data && result.data.apps) {
                const data = [];
                if (result.data.apps) {
                    result.data.apps.map((item) => {
                        const index = props.competitor.findIndex(i => i.appId === item.app_id);
                        if (index === -1) {
                            data.push(item);
                        }
                    })
                    setListResult(data);
                }
            }
        }
        setIsLoading(false);
    }

    const selectItem = (item) => () => {
        setItemActive(item)
    }

    return (
        <Modal width={420} title="Add Competitor" visible={true} footer={null} onOk={handleOk} onCancel={handleCancel}>
            <div className="popup-add-competitor">
                <div className="input-competitor">
                    <div className="search-data">
                        <Search
                            placeholder="Application name"
                            onSearch={onSearch}
                        />
                    </div>
                    {isLoading ?
                        <Spin style={{width: '100%'}}
                            indicator={
                                <LoadingOutlined style={{ marginTop: 30, fontSize: 24 }} spin />
                            }
                        />
                        :
                        listResult && listResult.length === 0
                            ?
                            <div className="no-data">No result</div>
                            :
                            <>
                                <div className="result-search">
                                    {listResult && listResult.map((item, index) => {
                                        return (
                                            <div className={`item-name  ${itemActive.app_id === item.detail.app_id ? "item-active" : ""}`} key={index} onClick={selectItem(item.detail)}>{item.detail ? item.detail.name : ""}</div>
                                        )
                                    })}
                                </div>
                                <div className="item-active">

                                </div>
                                <div className="save-competitor">
                                    <Button
                                        htmlType="submit"
                                        className="button-add"
                                        type="primary"
                                        onClick={saveCompetitor}
                                        style={{ width: 320 }}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </>
                    }
                </div>
            </div>
        </Modal>
    )
}
export default ModalCompetitor;