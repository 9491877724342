import Homepage from '../views/homepage/Homepage';
import LandingPage from '../views/landingpage/LandingPage';
import Category from '../views/categories/Category';
import Developer from '../views/developers/Developer';
import privacyPolicy from '../views/privacyPolicy/privacyPolicy';
import termsOfUse from '../views/termsOfUse/termsOfUse';
import aboutUs from '../views/aboutUs/aboutUs';
import Login from '../views/auth/Login';
import LoginGoogle from '../views/auth/LoginGoogle';
import SyncGoogleGa from '../views/profile/SyncGoogleGa';
import ChangePassword from '../views/auth/change_pass/ChangePassword';
// import Keys from "../views/keys/Keys";
 

const COMPONENT_MENU = [
	{
		component: LandingPage,
		url:  '/',
	},
	{
		component: LandingPage,
		url:  '/homepage',
	},
	{
		component: Homepage,
		url:  '/dashboard',
	},
	// {
	//   component: Keys,
	//   url:REACT_APP_BUILD_PATH+ "/keys",
	// },
	{
		component: Category,
		url:  '/categories',
	},
	{
		component: Developer,
		url:  '/developers',
	},
	{
		component: privacyPolicy,
		url:  '/privacy-policy',
	},
	{
		component: aboutUs,
		url:  '/about-us',
	},
	{
		component: Login,
		url:  '/login',
	},
	{
		component: LoginGoogle,
		url:  '/google_login_callback',
	},
	{
		component: SyncGoogleGa,
		url:  '/ga_callback',
	},
	{
		component: ChangePassword,
		url:  '/change-password',
	},
	{
		component: termsOfUse,
		url:  '/terms-of-use',
	},
];

export { COMPONENT_MENU };
