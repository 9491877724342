import "./aboutUs.scss";
import React, { useEffect, useState } from "react";
import { Col, Button, Row } from "antd";
import feature from "../../assets/images/feature.png";
import feature_2 from "../../assets/images/feature2.png";
function aboutUs() {
  return (
    <div className="about-us container">
      <Row>
        <Col lg={24} span={24}>
          <main id="content" role="main" className>
            <div style={{ clear: "both" }} />
            {/* Begin Content */}
            <div id="system-message-container"></div>
            <content>
              <div
                className="item-page"
                itemScope
                itemType="http://schema.org/Article"
              >
                <meta itemProp="inLanguage" content="en-GB" />
                <div className="page-header">
                  <a href="/about-us" itemProp="url">
                    {" "}
                    <h2>About Us</h2>
                  </a>
                </div>
                <div itemProp="articleBody">
                  <p style={{ fontSize: "18px" }}>
                    <b>
                      “We’re passionate to craft numerous apps for marketers
                      around the world to become more effective at work and to
                      make everything count”
                    </b>
                  </p>
                  <h2 id="pp-general">Who We Are</h2>
                  <p>
                    Starting as a team of close friends from the same university in 2014, all share the same mission and are very eager to make online shopping easier for all users. Identifying the pain of having to manually submit, constantly send emails to notify customers and merchants, or edit every tiny bit of changes of quotations on Gmail, we develop a tool for merchants to send email notifications automatically to customers and merchants to get updated about quotes and close deals easier.
                    <br />
                    <br />
                    However, if you can't find the match between our available tool and what you are looking for, feel free to contact us at contact@omegatheme.com and let us know what you would like to do, our team will try our best to make our app best match with you.
                  </p>
                </div>
              </div>
            </content>
            {/* End Content */}
          </main>
        </Col>
      </Row>
      <Row type="flex" style={{ alignItems: "center", marginTop: "20px" }}>
        <Col lg={12} span={24}>
          <div>
            <img width="80%" src={feature_2} alt="123" />
          </div>
        </Col>
        <Col lg={12} span={24}>
          <div>
            <img width="80%" src={feature} alt="123" />
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default aboutUs;
