
import React, { useState, useEffect } from "react";
import { FetchApi } from '../../api/FetchAPI';
import ListApp from './components/ListApp';
import './TopMostReviews.scss';
function TopMostReviews() {
    const [data, setData] = useState([]);

    useEffect(() => {
        asyncFetch();
    }, []);
    const asyncFetch = async () => {
        let result = await FetchApi.getTopReviews();
        if (result) {
            setData(result.most_reviewed);
        }
    };
    const mostReviewed = {
        title: "Most reviewed",
        description: "Apps which have received the most new reviews in the last 30 days.",
        textLink: "View the top 50 apps that have gained the most reviews in the last 30 days",
    }
    return (
        <div className="top-most-reviews">
            <ListApp textData={mostReviewed} value={data ? data : ""}></ListApp>
        </div>
    )
}
export default TopMostReviews;