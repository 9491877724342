import "./termsOfUse.scss";

function termsOfUse() {
  return (
    <div className="privacy-policy container">
      <content>
        <div
          className="item-page"
          itemScope
          itemType="http://schema.org/Article"
        >
          <meta itemProp="inLanguage" content="en-GB" />
          <div className="page-header">
            <h2 itemProp="name">
              <a href="/terms-of-use" itemProp="url">
                {" "}
                TERMS OF SERVICE
              </a>
            </h2>
          </div>
          <div itemProp="articleBody">
            <h2>Overview</h2>
            <p>
              This website is operated by OmegaTheme. Throughout the site, the
              terms “we”, “us” and “our” refer to OmegaTheme. OmegaTheme offers
              this website, including all information, tools and services
              available from this site to you, the user, conditioned upon your
              acceptance of all terms, conditions, policies and notices stated
              here.
              <br />
              <br />
              By visiting our site and/ or purchasing something from us, you
              engage in our “Service” and agree to be bound by the following
              terms and conditions (“Terms of Service”, “Terms”), including
              those additional terms and conditions and policies referenced
              herein and/or available by hyperlink. These Terms of Service apply
              to all users of the site, including without limitation users who
              are browsers, vendors, customers, merchants, and/ or contributors
              of content.Please read these Terms of Service carefully before
              accessing or using our website. By accessing or using any part of
              the site, you agree to be bound by these Terms of Service. If you
              do not agree to all the terms and conditions of this agreement,
              then you may not access the website or use any services. If these
              Terms of Service are considered an offer, acceptance is expressly
              limited to these Terms of Service.
              <br />
              <br />
              Any new features or tools which are added to the current website
              shall also be subject to the Terms of Service. You can review the
              most current version of the Terms of Service at any time on this
              page. We reserve the right to update, change or replace any part
              of these Terms of Service by posting updates and/or changes to our
              website. It is your responsibility to check this page periodically
              for changes. Your continued use of or access to the website
              following the posting of any changes constitutes acceptance of
              those changes.
            </p>
            <h2>Section 1 - General Conditions </h2>
            <p>
              We reserve the right to refuse service to anyone for any reason at
              any time.
              <br />
              <br />
              You understand that your content (not including credit card
              information), may be transferred unencrypted and involve (a)
              transmissions over various networks; and (b) changes to conform
              and adapt to technical requirements of connecting networks or
              devices. Credit card information is always encrypted during
              transfer over networks.
              <br />
              <br />
              You agree not to reproduce, duplicate, copy, sell, resell or
              exploit any portion of the Service, use of the Service, or access
              to the Service or any contact on the website through which the
              service is provided, without express written permission by us.
              <br />
              <br />
              The headings used in this agreement are included for convenience
              only and will not limit or otherwise affect these Terms. If any
              term or provision of this Agreement is held to be invalid or
              unenforceable for any reason, such invalidity or unenforceability
              shall not affect any other term or provision hereof and this
              Agreement shall continue in full force and effect as if such
              invalid or unenforceable term or provision (to the extent of the
              invalidity or unenforceability) had not been contained herein.
            </p>
            <h2>
              Section 2 - Accuracy, Completeness And Timeliness of Information{" "}
            </h2>
            <p>
              We are not responsible if information made available on this site
              is not accurate, complete or current. The material on this site is
              provided for general information only and should not be relied
              upon or used as the sole basis for making decisions without
              consulting primary, more accurate, more complete or more timely
              sources of information. Any reliance on the material on this site
              is at your own risk.
            </p>
            <h2>Section 3 - Modifications to the service and prices</h2>
            <p>
              Prices for our products are subject to change without notice.
              <br />
              <br />
              We reserve the right at any time to modify or discontinue the
              Service (or any part or content thereof) without notice at any
              time.
              <br />
              <br />
              We shall not be liable to you or to any third-party for any
              modification, price change, suspension or discontinuance of the
              Service.
              <br />
              <br />
              All fees are paid on a monthly basis. <b>If</b> a <b>payment</b>{" "}
              is not processed on or before the <b>due date</b>, <b>your</b>{" "}
              right to use the tool shall immediately terminate.
            </p>
            <h2>Section 4 - Personal Information </h2>
            <p>
              Your submission of personal information through the store is
              governed by our Privacy Policy. To view our
              <a
                href="https://sasi.work/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy.
              </a>
            </p>
            <h2>Section 5 - Errors, Inaccuracies And Omissions</h2>
            <p>
              Occasionally there may be information on our site or in the
              Service that contains typographical errors, inaccuracies or
              omissions that may relate to product descriptions, pricing,
              promotions, offers. We reserve the right to correct any errors,
              inaccuracies or omissions, and to change or update information if
              any information in the Service or on any related website is
              inaccurate at any time without prior notice.
              <br />
              <br />
              We undertake no obligation to update, amend or clarify information
              in the Service or on any related website, including without
              limitation, pricing information, except as required by law. No
              specified update or refresh date applied in the Service or on any
              related website, should be taken to indicate that all information
              in the Service or on any related website has been modified or
              updated.
            </p>
            <h2>Section 6 - Prohibited Uses</h2>
            <p>
              In addition to other prohibitions as set forth in the Terms of
              Service, you are prohibited from using the site or its content:
              (a) for any unlawful purpose; (b) to solicit others to perform or
              participate in any unlawful acts; (c) to violate any
              international, federal, provincial or state regulations, rules,
              laws, or local ordinances; (d) to infringe upon or violate our
              intellectual property rights or the intellectual property rights
              of others; (e) to harass, abuse, insult, harm, defame, slander,
              disparage, intimidate, or discriminate based on gender, sexual
              orientation, religion, ethnicity, race, age, national origin, or
              disability; (f) to submit false or misleading information;(g) to
              create derivative works based upon the design embodied in the
              website itself (i) to modify, reverse engineer, disassemble, or
              decompile the Software, or any portion thereof (j) to make for any
              purpose including error correction, any modifications, adaptions,
              additions or enhancements to the Site or Our Content, including
              the modification of the paper or digital copies you may have
              downloaded (k) to license, sublicense, sell, resell, transfer,
              assign, distribute or otherwise commercially exploit the Service
              in any way (l) to create hybrid apps or platforms that use the
              same code base or (m) to remove or alter any trademark, trade
              names, product names, logo, copyright or other proprietary
              notices, legends, symbols or labels of the website.
            </p>
            <h2>
              Section 7 - Disclaimer of Warranties; Limitation of Liability
            </h2>
            <p>
              We do not guarantee, represent or warrant that your use of our
              service will be uninterrupted, timely, secure or error-free.
              <br />
              <br />
              We do not warrant that the results that may be obtained from the
              use of the service will be accurate or reliable. <br />
              <br />
              You expressly agree that your use of, or inability to use, the
              service is at your sole risk. The service and all products and
              services delivered to you through the service are (except as
              expressly stated by us) provided 'as is' and 'as available' for
              your use, without any representation, warranties or conditions of
              any kind, either express or implied, including all implied
              warranties or conditions of merchantability, merchantable quality,
              fitness for a particular purpose, durability, title, and
              non-infringement.
              <br />
              In no case shall OmegaTheme, our directors, officers, employees,
              affiliates, agents, contractors, interns, suppliers, service
              providers or licensors be liable for any injury, loss, claim, or
              any direct, indirect, incidental, punitive, special, or
              consequential damages of any kind, including, without limitation
              lost profits, lost revenue, lost savings, loss of data,
              replacement costs, or any similar damages, whether based in
              contract, tort (including negligence), strict liability or
              otherwise, arising from your use of any of the service or any
              products procured using the service, or for any other claim
              related in any way to your use of the service or any product,
              including, but not limited to, any errors or omissions in any
              content, or any loss or damage of any kind incurred as a result of
              the use of any error, omission or inaccuracy in the merchant
              content, and/or any Shopify content, APIs or other materials
              provided by Shopify. Because some states or jurisdictions do not
              allow the exclusion or the limitation of liability for
              consequential or incidental damages, in such states or
              jurisdictions, our liability shall be limited to the maximum
              extent permitted by law.
            </p>
            <h2>Section 8 - Indemnification</h2>
            <p>
              You agree to indemnify, defend and hold harmless OmegaTheme and
              our parent, subsidiaries, affiliates, partners, officers,
              directors, agents, contractors, licensors, service providers,
              subcontractors, suppliers, interns and employees, harmless from
              any claim or demand, including reasonable attorneys’ fees, made by
              any third-party due to or arising out of your breach of these
              Terms of Service or the documents they incorporate by reference,
              or your violation of any law or the rights of a third-party.
            </p>
            <h2>Section 9 - Severability</h2>
            <p>
              In the event that any provision of these Terms of Service is
              determined to be unlawful, void or unenforceable, such provision
              shall nonetheless be enforceable to the fullest extent permitted
              by applicable law, and the unenforceable portion shall be deemed
              to be severed from these Terms of Service, such determination
              shall not affect the validity and enforceability of any other
              remaining provisions.
            </p>
            <h2>Section 10 - Termination</h2>
            <p>
              The obligations and liabilities of the parties incurred prior to
              the termination date shall survive the termination of this
              agreement for all purposes.
              <br />
              <br />
              These Terms of Service are effective unless and until terminated
              by either you or us. You may terminate these Terms of Service at
              any time by notifying us that you no longer wish to use our
              Services, or when you cease using our site.If in our sole judgment
              you fail, or we suspect that you have failed, to comply with any
              term or provision of these Terms of Service, we also may terminate
              this agreement at any time without notice and you will remain
              liable for all amounts due up to and including the date of
              termination; and/or accordingly may deny you access to our
              Services (or any part thereof).
            </p>
            <h2>Section 11 - Changes to terms of service </h2>
            <p>
              You can review the most current version of the Terms of Service at
              any time at this page.
              <br />
              <br />
              We reserve the right, at our sole discretion, to update, change or
              replace any part of these Terms of Service by posting updates and
              changes to our website. It is your responsibility to check our
              website periodically for changes. Your continued use of or access
              to our website or the Service following the posting of any changes
              to these Terms of Service constitutes acceptance of those changes.
            </p>
            <h2>Section 12 - Contact Information </h2>
            <p>
              Questions about the Terms of Service should be sent to us at
              contact@omegatheme.com.
            </p>
            <h2>Last Updated</h2>
            <p>This privacy policy was last updated on: 27 August 2021</p>
          </div>
        </div>
      </content>
    </div>
  );
}
export default termsOfUse;
