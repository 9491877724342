const currentUserKey = "current-user";

function setToken(token) {
    localStorage.setItem('accessToken', token);
}

function getAccessToken() {
    return localStorage.getItem('accessToken');
}

function setAccessToken(accessToken) {
    return localStorage.setItem('accessToken', accessToken);
}
function setRefreshToken(refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
}
function getRefreshToken() {
    return localStorage.getItem('refreshToken');
}

function removeToken() {
    localStorage.removeItem('accessToken');
}


function removeRefreshToken() {
    localStorage.removeItem('refreshToken');
}

function setCurrentUser(currentUser) {
    localStorage.setItem(currentUserKey, currentUser);
}

function getCurrentUser() {
    return localStorage.getItem(currentUserKey);
}

function removeCurrentUser() {
    localStorage.removeItem(currentUserKey);
}

function isAuthenticated() {
    return getAccessToken() !== undefined && getAccessToken() !== null;
}

let Auth = {
    setToken: setToken,
    setCurrentUser: setCurrentUser,
    getCurrentUser: getCurrentUser,
    getAccessToken: getAccessToken,
    setRefreshToken: setRefreshToken,
    getRefreshToken: getRefreshToken,
    isAuthenticated: isAuthenticated,
    setAccessToken: setAccessToken,
    logout() {
        removeToken();
        removeCurrentUser();
        removeRefreshToken();
    }
};
export default Auth;
