
import './ItemCategory.scss';
import React from "react";
import {
    useHistory,
} from "react-router-dom";
function ItemCategory(props) {
    const items = props.value ? props.value : "";
    const itemsChild = props.value && props.value.child ? props.value.child : "";
    const history = useHistory();

    const openDetailCategory = (id) => () => {
        history.push(`/category/${id}`)
    }

    const openDetailCategoryItem = (id) => () => {
        history.push({
            pathname: `/category/${id}`,
            state: {parent: items ? items.slug : "", children: id}})
    }

    return (
        <div className="item-categories">
            <div className="title-categories">
                <div className="item-categories-detail">
                    <div className="item-name"><a href={"/category/" + items.slug}>{items.text}</a></div>
                    <div className="amount-app">{items.app_count} apps</div>
                </div>
            </div>
            {itemsChild ? itemsChild.map((item, index) => {
                return (
                    <div className="list-item-categories" key={"" + index}>
                        <div className="item-categories-detail" >
                            <div className="item-name"><a href={"/category/" + item.slug}>{item.text}</a></div>
                            <div className="amount-app">{item.app_count} apps</div>
                        </div>
                    </div>
                )
            }) : ""}

        </div>
    )
}
export default ItemCategory;