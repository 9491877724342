import './SearchDetail.scss';
import {
    useHistory
} from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { FetchApi } from '../../api/FetchAPI';
import { Row, Col } from 'antd';
import { Pagination } from 'antd';
import AppResult from './AppResult';

const style = { marginTop: "15px" };

function SearchDetail(props) {
    const [dataApps, setDataApps] = useState([]);
    const [dataPartners, setDataPartners] = useState([]);
    const page = useRef(1);
    const pageSizeOptions = useRef([12, 24, 48, 96]);
    const [totalPage, setTotalPage] = useState(0);
    const paramSearch = new URLSearchParams(props.location.search).get("q");
    const per_page = useRef(12);
    const history = useHistory();
    useEffect(() => {
        asyncFetch(paramSearch, page.current);
    }, []);
    const asyncFetch = async (paramSearch, page) => {
        const result = await FetchApi.searchData(paramSearch, page, per_page.current);
        if (result && result.code === 0) {
            setDataApps(result.data.apps);
            setDataPartners(result.data.partners);
            setTotalPage(result.total)
        }
    };
    const onChangePage = () => {
        asyncFetch(paramSearch, page.current);
    }
    const onChangeSizePage = () => {
        asyncFetch(paramSearch, page.current);
    }
    const openDeveloper = (id) => () => {
        history.push(`/developer/${id}`)
    }

    return (
        <div className="search-shopify">
            <div className="list-search-detail">
                <div className="info-search-detail">
                    <div className="title-name">Search</div>
                    <div className="conten-search"><span>You searched for "{paramSearch}"</span></div>
                </div>

                <div className="body-search-detail">
                    <div className="title-app">
                        Apps
                    </div>
                    {dataApps.length > 0 ?
                        <div className="list-item">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                {dataApps ? dataApps.map((item, index) => {
                                    return (
                                        <Col style={style} className="gutter-row" span={8} key={"" + index}>
                                            <AppResult value={item}></AppResult>
                                        </Col>
                                    )
                                }) : ''}

                            </Row>
                        </div> : "No matching apps"}

                </div>
                {totalPage > 0 ?
                    <div className="pagination">
                        <Pagination
                            pageSize={per_page.current}
                            current={page.current}
                            onChange={(pageNumber) => {
                                page.current = pageNumber;
                                onChangePage();
                            }}
                            total={totalPage}
                            pageSizeOptions ={pageSizeOptions.current}
                            onShowSizeChange={
                                (current, size) => {
                                    per_page.current = size;
                                    onChangeSizePage();
                                }
                            }
                        />
                    </div> : ""}

                {dataPartners.length > 0 ? <div className="result-developer">
                    <div className="title">
                        Developers
                        </div>
                    <div className="content-item">
                        <Row className="gutter-column" gutter={[32, 12]}>
                            {dataPartners ? dataPartners.map((item, index) => {
                                return (
                                    <Col className="gutter-row" span={8} key={"" + index}>
                                        <a onClick={openDeveloper(item.id)}>{item.name}</a>
                                    </Col>
                                )
                            }) : ''}

                        </Row>
                    </div>
                </div> : ""}

            </div>
        </div>
    )

}
export default SearchDetail;
