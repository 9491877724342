import React, {useEffect} from "react";
import { Form, Input, Button, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./Login.scss";
import { FetchApi } from "../../api/FetchAPI";
import Auth from "../../store/Authentication";

function Login() {
  useEffect(() => {
    const error = new URLSearchParams(window.location.search).get("error");
    if (error) {
        message.error("Login google error!")
    }
  }, []);
 
  const login = async (values) => {
    if (values && values.username && values.password) {
      const data = {
        email: values.username,
        password: values.password,
      };
      const result = await FetchApi.login(data);
      if (result && result.code === 0) {
        // message.success("Login success");
        Auth.setAccessToken(result.token);
        Auth.setRefreshToken(result.refresh_token);
        Auth.setCurrentUser(result.fullname);
        window.location.href = "/dashboard";
      } else if (result && result.code === 101) {
        message.error(result.message);
      } else {
        message.error("Login error");
      }
    }
  };

  // const linkLoginGoogle = "https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?response_type=code&client_id=148062466966-u6u16oefnuod2r2cjfa0p0m6juspg7p0.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fgoogle_login_callback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20openid&state=HbMgDZyVhdU9W4CWLwF0QAyyD20FS4&access_type=offline&flowName=GeneralOAuthFlow";

  const signGoogle = async () => {
    const result = await FetchApi.getLinkLoginGoogle();
    if (result.code == 0) {
      window.location.href = result.authorization_url;
    }
  };

  return (
    <div className="login-web">
      <div className="authen-box">
        <div className="title">{/* <img src={logoLogin} alt="logo" /> */}</div>
        <Form layout="vertical" className="form-login" onFinish={login}>
          <Form.Item
            label="UserName"
            name="username"
            required
            rules={[
              {
                required: true,
                message: "Please input username",
              },
            ]}
          >
            <Input
              className="form-input"
              placeholder="Input username"
              style={{ width: 320 }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            required
            rules={[
              {
                required: true,
                message: "Please input password",
              },
            ]}
          >
            <Input.Password
              className="form-input"
              placeholder="Input password"
              style={{ width: 320 }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              onClick={login}
              className="button-login"
              type="primary"
              style={{ width: 320 }}
            >
              Login
            </Button>
          </Form.Item>
        </Form>

        <div className="login-google" onClick={signGoogle}>
          <div className="google-icon-wrapper">
            <img
              className="google-icon"
              src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
            />
          </div>
          <p className="btn-text">
            <b>Sign in with google</b>
          </p>
        </div>

        {/* <div className="privacy-term">
          <div className="privacy">
            <a href="/about_us" target="_blank">
              About Us
            </a>
          </div>
          <div className="privacy">
            <a href="/privacy-policy" target="_blank">
              Privacy
            </a>
          </div>
          <div className="term-of-service">
            <a href="/terms-of-use" target="_blank">
              Terms Of Service
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default Login;
