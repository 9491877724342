import React, { useEffect, useRef, useState } from 'react';
import './Main.scss';
import 'antd/dist/antd.css';
import {
	BrowserRouter as Router,
	Link,
	Route,
	useHistory,
	Redirect,
	Switch,
	useLocation,
} from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import LandingPage from '../views/landingpage/LandingPage'
import { Dropdown, Layout, Menu, Input, Avatar, Button, Space } from 'antd';
import FooterSasi from './Footer';
import { MENU_ITEM, COMPONENT_MENU } from '../constants';
import TopNewApp from '../views/homepage/TopNewApp';
import TopMovers from '../views/homepage/TopMovers';
import TopMostReviews from '../views/homepage/TopMostReviews';
import DetailApp from '../views/homepage/DetailApp';
import DeveloperDetail from '../views/developers/DeveloperDetail';
import DetailCategory from '../views/categories/DetailCategory';
import DetailKey from '../views/keys/DetailKey';
import SearchDetail from '../views/search/SearchDetail';
import Auth from '../store/Authentication';
import logo from '../assets/images/app/logo.png';

const { Header, Content, Footer } = Layout;
const { Search } = Input;

function Main() {
	const allowedRoutes = useRef(COMPONENT_MENU);
	const history = useHistory();
	const menuItems = MENU_ITEM;
	const [selectedKey, setSelectedKey] = useState(null);
	const [isShowProfile, setIsShowProfile] = useState(Auth.isAuthenticated());
	// const userAdmin = Auth.getCurrentUser();
	// const [userName, setUserName] = useState(userAdmin ? userAdmin : "Admin");
	const location = useLocation();

	useEffect(() => {
		setSelectedKey(null);
	}, [location]);

	const onClickHomepage = () => {
		setSelectedKey(null);
	};

	return (
		<Router>
			<Switch>
				<Layout className="sasi-layout">
					<Header className="sasi-layout-background">
						<div className="header-sasi container">
							<div className="menu-sasi">
								<Route>
									<div className="logo-sasi">
										<Menu>
											<Menu.Item key="homepage" onClick={onClickHomepage}>
												<img src={logo} className="img-responsive" alt="Logo" />
												<Link to="/" />
											</Menu.Item>
											<Menu.Item key="homepage" onClick={onClickHomepage}>
												<span style={{ color: 'white' }}>Request a Quote ‑ Hide Prices</span>
												<Link to="/"/>
											</Menu.Item>
										</Menu>
									</div>
								</Route>
							</div>
						</div>
					</Header>
					<Content style={{ overflow: 'initial' }}>
						<div className="content-menu">
							{COMPONENT_MENU.map((item, index) => {
								return <Route key={'' + index} exact path={`${item.url}`} component={item.component} />;
							})}
							{/* <Route key={'' + index} exact path={`${item.url}`} component={item.component} />
							<LandingPage/> */}
						</div>
					</Content>
					<Footer style={{ textAlign: 'center', backgroundColor: '#000e2d' }}>
						<FooterSasi></FooterSasi>
					</Footer>
				</Layout>
			</Switch>
		</Router>
	);
}

export default Main;
