import React from "react";
import './ItemDeveloper.scss';
import {
    useHistory
} from "react-router-dom";
function ItemDeveloper(props) {
    const history = useHistory();
    const openDetailDeveloper = (id) => () => {
        history.push(`/developer/${id}`)
    }

    return (
        <div className="item-developers">
            <div className="app-name">
                <a onClick={openDetailDeveloper(props.value.id)}>{props.value.name}</a>
            </div>
            <div className="amount-app">
                {props.value.app_count} apps
            </div>
        </div>
    )
}
export default ItemDeveloper;