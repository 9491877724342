import { FetchApi } from "../../api/FetchAPI";
import React, { useEffect } from "react";
import Auth from "../../store/Authentication";
function LoginGoogle() {
    const state = new URLSearchParams(window.location.search).get("state");
    const code = new URLSearchParams(window.location.search).get("code");

    useEffect(async () => {

        async function loginGoogle() {

            if (state && code) {
                const result = await FetchApi.loginGoogle(state, code);
                if (result && result.code == 0) {
                    Auth.setAccessToken(result.token);
                    Auth.setCurrentUser(result.fullname);
                    Auth.setRefreshToken(result.refresh_token);
                    window.location.href = "/";
                } else {
                    window.location.href = "/login?error=error";
                }
            } else {
                window.location.href = "/login?error=error";
            }
        }

        loginGoogle();
    }, []);
    return (
        <></>
    );

}
export default LoginGoogle;
