import { useHistory } from "react-router-dom";
import { FetchApi } from "../../api/FetchAPI";
import React, { useEffect } from "react";
function SyncGoogleGa() {
    const history = useHistory();
    const state = new URLSearchParams(window.location.search).get("state");
    const code = new URLSearchParams(window.location.search).get("code");
    const error = new URLSearchParams(window.location.search).get("error");
    useEffect(async () => {
        async function syncGoogle() {
            if (error) {
                const result = await FetchApi.gaSyncGoogle(state, "");
                if (result) {
                    const id = result.app_id;
                    if (id) {
                        history.push(`/app/${id}?error=access_denied`)
                    } else {
                        history.push("/")
                    }
                }
            } else {
                if (state && code) {
                    const result = await FetchApi.gaSyncGoogle(state, code);
                    if (result && result.code == 0) {
                        const id = result.app_id;
                        if (id) {
                            history.push(`/app/${id}`)
                        } else {
                            history.push("/")
                        }
                    }
                }
            }
            
        }
        syncGoogle();
    }, []);
    return (
        <></>
    );

}
export default SyncGoogleGa;
