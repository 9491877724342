import './Homepage.scss';
import NewApps from './components/NewApps';
import React, { useState, useEffect } from "react";
import { FetchApi } from "../../api/FetchAPI";
function Homepage() {
    const [dataHomepage, setDataHomepage] = useState();
    useEffect(async () => {
        async function fetchHomepage() {
            let result = await FetchApi.getHome();
            if (result) {
                setDataHomepage(result);
            }
        }
        fetchHomepage();
    }, []);

    const newApps = {
        title: "New Apps",
        description: "Apps which have recently appeared in the app store.",
        textLink: "View the 50 newest apps in the app store",
        link: "/top-new-apps"
    }
    const topMovers = {
        title: "Top movers",
        description: "Apps which have increased their position in the app store the most in the last 30 days.",
        textLink: "View the top 50 moving apps in the last 30 days",
        link: "/top-movers"
    }
    const mostReviewed = {
        title: "Most reviewed",
        description: "Apps which have received the most new reviews in the last 30 days.",
        textLink: "View the top 50 apps that have gained the most reviews in the last 30 days",
        link: "/top-reviewed"
    }
    return (
        <div className="layout-homepage">
            <div className="total-statistic">
                <div className="total-apps">
                    <span>{dataHomepage ? dataHomepage.app_count.toLocaleString() : ""}</span> <span>total apps</span>
                </div>
                <div className="total-developers">
                    <span>{dataHomepage ? dataHomepage.partner_count.toLocaleString() : ""}</span> <span>total developers</span>
                </div>
                <div className="total-reviews">
                    <span>{dataHomepage ? dataHomepage.review_count.toLocaleString() : ""}</span> <span>total reviews</span>
                </div>
            </div>
            <div className="content-homepage">
                <div className="new-app">
                    <NewApps textData={newApps} value={dataHomepage ? dataHomepage.new_apps : ""}></NewApps>
                </div>
                <div className="top-movers">
                    <NewApps textData={topMovers} value={dataHomepage ? dataHomepage.top_movers : ""}></NewApps>
                </div>
                <div className="most-reivewed">
                    <NewApps textData={mostReviewed} value={dataHomepage ? dataHomepage.most_reviewed : ""}></NewApps>
                </div>
            </div>

        </div>
    )
}
export default Homepage;