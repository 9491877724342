import ItemApp from './ItemApp';
import './NewApps.scss';
import React from "react";
import { Row, Col, Divider } from 'antd';
import {
    BrowserRouter as Router,
    Link,
    Route,
    useHistory
} from "react-router-dom";
// const style = { background: '#0092ff', padding: '8px 0' };
function NewApps(props) {
    const history = useHistory();

    const openLink = () => {
        history.push(props.textData.link);
    }
    return (
        <div className="new-apps-detail">
            <div className="info-new-apps">
                <div className="title-name">{props.textData.title}</div>
                <div className="title-description">{props.textData.description}</div>
            </div>
            <div className="body-new-apps">
                <div className="newest-apps">
                    <a onClick={openLink}>{props.textData.textLink}</a>
                </div>
                <div className="list-item">
                    <Row gutter={[32, 12]}>
                        {props.value ? props.value.map((item, index) => {
                            return (
                                <Col className="gutter-row" span={8} key={"" + index}>
                                    <ItemApp value={item} type={props.textData.title}></ItemApp>
                                </Col>
                            )
                        }) : ''}

                    </Row>
                </div>
                <div className="newest-apps"><a onClick={openLink}>{props.textData.textLink}</a></div>
            </div>
        </div>
    )
}

export default NewApps;