import React, { useRef } from "react";
import { Row, Col, Form, Button, Input, message } from "antd";
import "./ChangePassword.scss";
import { FetchApi } from "../../../api/FetchAPI";
import {
  useHistory,
} from "react-router-dom";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

function ChangePassword() {
  const password = useRef("");
  const newPass = useRef("");
  const confirmPass = useRef("");
  const history = useHistory();
  const [form] = Form.useForm();

  const validatePassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      form.validateFields(["confirmPass"]);
      const re = /(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]*$/;
      if (value === undefined || value.length === 0) {
        return Promise.reject("New Password is required");
      } else {
        return Promise.resolve();
      }
    },
  });
  const validateOldPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      form.validateFields(["confirmPass"]);
      const re = /(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]*$/;
      if (value === undefined || value.length === 0) {
        return Promise.reject("Old Password is required");
        // } else if (!re.test(value)) {
        //   return Promise.reject("数字、英字をそれぞれ1文字以上含めてください。");
        // } else if (value.length < 8 || value.length > 32) {
        //   return Promise.reject("8文字以上32文字以内で入力してください。");
      } else {
        return Promise.resolve();
      }
    },
  });

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const re = /(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]*$/;
      if (value === undefined || value.length === 0) {
        return Promise.reject("Confirm password is required");
      } else if (value !== form.getFieldValue("newPass")) {
        return Promise.reject("Confirm password not match");
      } else {
        return Promise.resolve();
      }
    },
  });

  const onChangePass = async () => {
    try {
      const result = await FetchApi.changePassword(
        password.current,
        newPass.current
      );
      if (result.code == 0) {
        message.success(result.message);
        history.push("/");
        form.resetFields();
      } else {
        message.error(result.message);
      }
    } catch (errorInfo) { }
  };

  return (
    <div className="change-passwrod">
      <div className="authen-box">
        <Col span={20}>
          <Form form={form} {...layout} className="form-login">
            <Form.Item
              label="Old password"
              name="password"
              required
              rules={[validateOldPassword]}
              initialValue={password.current}
            >
              <Input.Password
                onChange={(e) => {
                  password.current = e.target.value;
                }}
                className="form-input"
              />
            </Form.Item>
            <Form.Item
              label="New password"
              name="newPass"
              required
              rules={[validatePassword]}
              initialValue={newPass.current}
            >
              <Input.Password
                onChange={(e) => {
                  newPass.current = e.target.value;
                }}
                className="form-input"
              />
            </Form.Item>
            <Form.Item
              label="Confirm password"
              name="confirmPass"
              required
              rules={[validateConfirmPassword]}
              initialValue={confirmPass.current}
            >
              <Input.Password
                onChange={(e) => {
                  confirmPass.current = e.target.value;
                }}
                className="form-input"
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 12 }}>
              <Button
                style={{
                  backgroundColor: "#1F0427",
                  color: "white",
                  borderRadius: 5,
                  height: 36,
                }}
                htmlType="submit"
                onClick={onChangePass}
              >
                Change password
              </Button>
            </Form.Item>
          </Form>
        </Col>


      </div>
    </div>
  );
}

export default ChangePassword;
