import Auth from "../store/Authentication";
import Api from "../constants/Api";
import { message } from "antd";


const CANT_CONNECT_INTERNET = "Can't connect internet";
const NETWORK_ERROR = "Network error";
const SIZE = 10;

let responseRefresh;

const refreshToken = async () => {
  if (!Auth.getRefreshToken()) {
    window.location.href = "/login";
    return;
  }
  const api = Api.refreshToken();
  try {
    if (!responseRefresh) {
      responseRefresh = true;
      responseRefresh = await fetch(api, {
        method: 'post',
        headers: new Headers({
          'Authorization': `Bearer ${Auth.getRefreshToken()}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
      });
    }
    const res = await responseRefresh.json();
    if (res.code == 0) {
      Auth.setAccessToken(res.token);
      return {
        success: true,
        access_token: res.token,
      };
    }

    if (responseRefresh.status === 500) {
      Auth.logout();
      window.location.href = "/login";
    }
    return {
      success: false,
      message: "Server error",
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  } finally {
    responseRefresh = undefined;
  }
};

function fetchWithTimeOut(promise, ms = 25000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error(CANT_CONNECT_INTERNET));
    }, ms);
    promise.then(resolve, reject);
  });
}

const CommonCall = async (api, header) => {
  try {
    const accessToken = Auth.getAccessToken();
    let headers;
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      };
    }
    if (header && (header.method === "POST" || header.method === "PUT")) {
      headers = {
        ...headers,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      };
    }
    let head = {
      ...header,
      headers,
    };

    const response = await fetchWithTimeOut(
      fetch(api, {
        ...head,
        credentials: "omit",
      })
    );

    if (response.status === 500) {
      return {
        code: response.status,
        message: "Server error",
        success: false,
      };
    }

    if (response.status === 200) {
      return await response.json();
    }

    if (response.status === 401) {
      //refresh token
      const resToken = await refreshToken();

      if (resToken.success) {
        const newHeaders = {
          ...headers,
          Authorization: `Bearer ${resToken.access_token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Request-Headers": "*",
        };
        const newHead = {
          ...head,
          headers: newHeaders,
        };
        const responseRefeshToken = await fetch(api, newHead);
        const resultRefeshToken = await responseRefeshToken.json();
        return resultRefeshToken;
      } else {
        return {
          code: response.code,
          success: false,
        };
      }
    } else {
      const resJson = await response.json();
      return {
        code: resJson.status,
        message: resJson.message,
        success: false,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: "Network error",
    };
  }
};

const FetchApi = {
  login: async (data) => {
    const body = {
      username: data.email,
      password: data.password,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.login;
    const result = await CommonCall(api, header);
    return result;
  },

  getCategories: async () => {
    const api = Api.getCategories();
    const result = await CommonCall(api);
    return result;
  },

  getKeyword: async () => {
    const api = Api.getKeys();
    const result = await CommonCall(api);
    return result;
  },

  getApps: async () => {
    const api = Api.getApps();
    const result = await CommonCall(api);
    return result;
  },

  getHome: async () => {
    const api = Api.getHome();
    const result = await CommonCall(api);
    return result;
  },

  getDevelopers: async (page, size) => {
    const body = {
      page: page,
      per_page: size
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.getDevelopers();
    const result = await CommonCall(api, header);
    return result;
  },

  getDetailDeveloper: async (id, page, size) => {
    const body = {
      per_page: size,
      page: page,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.getDetailDeveloper(id);
    const result = await CommonCall(api, header);
    return result;
  },

  getConversationKey: async (id, page, size) => {
    const body = {
      per_page: size,
      page: page,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.getConversationKey(id);
    const result = await CommonCall(api, header);
    return result;
  },

  searchData: async (search, page, size) => {
    const body = {
      q: search,
      per_page: size,
      page: page,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.searchData();
    const result = await CommonCall(api, header);
    return result;
  },

  getProfile: async () => {
    const api = Api.getProfile();
    const result = await CommonCall(api);
    return result;
  },
  gaLogin: async (id) => {
    const api = Api.gaLogin(id);
    const result = await CommonCall(api);
    return result;
  },
  gaDisconnect: async (id) => {
    const api = Api.gaDisconnect(id);
    const result = await CommonCall(api);
    return result;
  },

  getDetailApp: async (id, host_id, fromDate, toDate) => {
    let api;
    if (fromDate && toDate) {
      if (host_id && id !== host_id) {
        api = Api.getDetailAppHostRange(id, host_id, fromDate, toDate);
      } else {
        api = Api.getDetailAppRange(id, fromDate, toDate);
      }
    } else {
      if (host_id && id !== host_id) {
        api = Api.getDetailAppHost(id);
      } else {
        api = Api.getDetailApp(id, host_id);
      }
    }
    const result = await CommonCall(api);
    return result;
  },

  getTopNewApps: async () => {
    const api = Api.getTopNewApps();
    const result = await CommonCall(api);
    return result;
  },
  getTopMovers: async () => {
    const api = Api.getTopMovers();
    const result = await CommonCall(api);
    return result;
  },
  getTopReviews: async () => {
    const api = Api.getTopReviews();
    const result = await CommonCall(api);
    return result;
  },

  getConversationCategory: async (id, page, per_page) => {
    const body = {
      per_page: per_page,
      page: page,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.getConversationCategory(id);
    const result = await CommonCall(api, header);
    return result;
  },

  createKeyword: async (keyword, id) => {
    const body = {
      keyword: keyword,
      app_id: id
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.createKeyword();
    const result = await CommonCall(api, header);
    return result;
  },

  reloadKeyword: async (id) => {
    const body = {
      app_id: id
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.reloadKeyword();
    const result = await CommonCall(api, header);
    return result;
  },

  deleteKeyword: async (keyword, id) => {
    if (id) {
      const body = {
        keyword: keyword,
        app_id: id
      };
      const header = {
        method: "POST",
        body: JSON.stringify(body),
      };
      const api = Api.deleteKeyword();
      const result = await CommonCall(api, header);
      return result;
    } else {
      const body = {
        keyword: keyword
      };
      const header = {
        method: "POST",
        body: JSON.stringify(body),
      };
      const api = Api.deleteKeyword();
      const result = await CommonCall(api, header);
      return result;
    }
  },

  changePassword: async (oldPassword, newPassword) => {
    const header = {
      method: "POST",
      body: JSON.stringify({
        password: oldPassword,
        new_password: newPassword,
      }),
    };
    const api = Api.changePassword;
    const result = await CommonCall(api, header);
    return result;
  },

  getLinkLoginGoogle: async () => {
    const api = Api.urlLogin();
    const result = await CommonCall(api);
    return result;
  },

  loginGoogle: async (state, code) => {
    const api = Api.loginGoogle(state, code);
    const result = await CommonCall(api);
    return result;
  },

  gaSyncGoogle: async (state, code) => {
    const api = Api.gaSyncGoogle(state, code);
    const result = await CommonCall(api);
    return result;
  },

  changeKeywordInChart: async (appId, keyword, showInChart) => {
    const header = {
      method: "POST",
      body: JSON.stringify({
        app_id: appId,
        keyword: keyword,
        show_in_chart: showInChart,
      }),
    };
    const api = Api.changeKeywordInChart()
    const result = await CommonCall(api, header);
    return result;
  },

  saveKeywordPriority: async (appId, listKeword) => {
    const header = {
      method: "POST",
      body: JSON.stringify({
        app_id: appId,
        keyword_list: listKeword,
      }),
    };
    const api = Api.saveKeywordPriority()
    const result = await CommonCall(api, header);
    return result;
  },

  addCompetitor: async (appId, compareId) => {
    const header = {
      method: "POST",
      body: JSON.stringify({
        app_id: appId,
        compare_id: compareId,
      }),
    };
    const api = Api.addCompetitor()
    const result = await CommonCall(api, header);
    return result;
  },

  deleteCompetitor: async (appId, compareId) => {
    const header = {
      method: "POST",
      body: JSON.stringify({
        app_id: appId,
        compare_id: compareId,
      }),
    };
    const api = Api.deleteCompetitor()
    const result = await CommonCall(api, header);
    return result;
  },

  getCompetitor: async (appId) => {
    const header = {
      method: "POST",
      body: JSON.stringify({
        app_id: appId
      }),
    };
    const api = Api.getCompetitor()
    const result = await CommonCall(api, header);
    return result;
  }

}

export { FetchApi, CommonCall };
