import ItemCategory from "./components/ItemCategory";
import './Category.scss';
import React, { useState, useEffect } from "react";
import { FetchApi } from "../../api/FetchAPI";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";


function Category() {
    const [dataCategories, setDataCategories] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(async () => { 
        fetchCategories();
    }, []);

    async function fetchCategories() {
        setIsLoading(true);
        let result = await FetchApi.getCategories();
        if (result && result.cats) {
            setDataCategories(result.cats);
        }
        setIsLoading(false);
    }

    const renderedList = dataCategories ? dataCategories.map((item, index) => {
        return (
            <div className="item-list" key={"" + index}>
                <ItemCategory value={item}>
                </ItemCategory>
            </div>
        )
    }) : "";
    return (
        <div className="categories">
            <div className="header-categories">
                Categories
            </div>
            {/* {renderedList} */}

            {isLoading ?
                <Spin style={{ width: '100%' }}
                    indicator={
                        <LoadingOutlined style={{ marginTop: 30, fontSize: 24 }} spin />
                    }
                />
                :
                renderedList
            }


            {/* <Router>
                <div className="list-categories" >
                    {renderedList}
                    <Route path="category/:category.ID" component={DetailCategory} />
                </div>
            </Router> */}

        </div>
    )
}
export default Category;