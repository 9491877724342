import './LandingPage.scss';
import React, { useRef } from 'react';
import { Link, useHistory, BrowserRouter as Router } from 'react-router-dom';
import { Col, Button, Row } from 'antd';
import introIcon from '../../assets/images/icon1.png';
import feature from '../../assets/images/feature.png';
import feature_2 from '../../assets/images/feature2.png';
import feature1 from '../../assets/images/googleFeed/data-rich.jpg';
import feature2 from '../../assets/images/googleFeed/no_data_mismatch.jpg';
import feature3 from '../../assets/images/googleFeed/workflow.jpg';
import { CheckOutlined } from '@ant-design/icons';
function LandingPage() {
	const features = useRef(null);
	const history = useHistory();
	const handleLogin = () => {
    	window.open('https://apps.shopify.com/request-for-quote-by-omega');
	};

	const scrollToFeatures = () => features.current.scrollIntoView();
	return (
		<Router>
			<div className="layout-landing-page">
				<div className="layout-landing-page-intro">
					<div className="container">
						<Row type="flex" style={{ alignItems: 'center' }}>
							<Col lg={12} span={24}>
								<h1 className="title">Request a Quote ‑ Hide Prices</h1>
								<h2 className="description">Request a quote, customize quote form, send email notifications about quotation process</h2>
								<div className="cta-btn">
									<Button type="primary" size={'large'}>
										<p onClick={handleLogin}>Try For Free</p>
									</Button>
									<Button style={{ marginLeft: '10px' }} ghost size={'large'} onClick={scrollToFeatures}>
										Learn More
									</Button>
								</div>
							</Col>
							<Col lg={12} span={24}>
								<img width="80%" src={introIcon} alt="123" />
							</Col>
						</Row>
					</div>
				</div>
				<div className="layout-landing-page-features" ref={features}>
					<div className="container">
						<Row type="flex" style={{ alignItems: 'center' }}>
							<Col span={24}>
								<h2 className="title">Features</h2>
								<h3 className="description">
									Request a quote, customize quote form, send email notifications about quotation process
								</h3>
							</Col>
						</Row>
						<Row type="flex" style={{ alignItems: 'center', marginTop: '20px' }}>
							<Col lg={8} span={24}>
								<div className="layout">
									<div>
										<img
											width="85%"
											style={{ borderRadius: '5px' }}
											src={feature3}
											alt="Automate email notification"
										/>
									</div>
									<div className="layout-title">Automate email notification</div>
									<div className="layout-description">
										Effortlessly send quotes and notifications for both customers and merchants with email integration. Stay updated with quote discussion.
									</div>
								</div>
							</Col>
							<Col lg={8} span={24}>
								<div className="layout">
									<div>
										<img
											width="85%"
											style={{ borderRadius: '5px' }}
											src={feature1}
											alt="Submit quotation request"
										/>
									</div>
									<div className="layout-title">Submit quotation request</div>
									<div className="layout-description">
										Let customers quickly make a bargain with merchants by “Add to quote” button. They can include requests or specific demands for products.
									</div>
								</div>
							</Col>{' '}
							<Col lg={8} span={24}>
								<div className="layout">
									<div>
										<img
											width="85%"
											style={{ borderRadius: '5px' }}
											src={feature2}
											alt="Close deal with invoices"
										/>
									</div>
									<div className="layout-title">Close deal with invoices</div>
									<div className="layout-description">
										Automatically send invoices with custom fees or pricing to close deals with customers. Convert more quotes into orders for ecommerce stores.
									</div>
								</div>
							</Col>{' '}
						</Row>{' '}
						<Row type="flex" style={{ alignItems: 'center', marginTop: '20px' }}>
							<Col lg={12} span={24}>
								<div>
									<img width="80%" src={feature_2} alt="123" />
								</div>
							</Col>
							<Col lg={12} span={24}>
								<div>
									<img width="80%" src={feature} alt="123" />
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</Router>
	);
}
export default LandingPage;
