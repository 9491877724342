import ItemDeveloper from "./components/ItemDeveloper";
import './Developer.scss';
import { Row, Col, Pagination, Spin } from 'antd';
import React, { useState, useEffect, useRef } from "react";
import { FetchApi } from "../../api/FetchAPI";
import { LoadingOutlined } from "@ant-design/icons";

function Developer() {

    const [dataDevelopers, setDataDevelopers] = useState();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const page = useRef(1);
    const perPage = 24;
    useEffect(async () => {
        fetchDevelopers();
    }, []);

    const onChangePage = () => {
        fetchDevelopers();
    }
    async function fetchDevelopers() {
        setIsLoading(true);
        let result = await FetchApi.getDevelopers(page.current, perPage);
        if (result) {
            setDataDevelopers(result.partners);
            setData(result)
        }
        setIsLoading(false);
    }

    return (
        <div className="developer">
            <div className="header-developer">
                Developers
            </div>
            {isLoading ?
                <Spin style={{ width: '100%' }}
                    indicator={
                        <LoadingOutlined style={{ marginTop: 30, fontSize: 24 }} spin />
                    }
                />
                :
                <div className="list-developer">
                    <Row gutter={[32, 32]}>
                        {dataDevelopers ? dataDevelopers.map((item, index) => {
                            return (
                                <Col className="gutter-row" span={12} key={"" + index}>
                                    <ItemDeveloper value={item}></ItemDeveloper>
                                </Col>
                            )
                        }) : ''}
                    </Row>
                    <div className="pagination">
                        <Pagination
                            pageSize={perPage}
                            current={page.current}
                            onChange={(pageNumber) => {
                                page.current = pageNumber;
                                onChangePage();
                            }}
                            total={data ? data.total : ""}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            }

        </div>
    )
}
export default Developer;