import './DetailApp.scss';
import React, { useState, useEffect, useRef } from "react";

import { FetchApi } from '../../api/FetchAPI';
import {
    StarFilled, UpOutlined, DownOutlined, SearchOutlined, ReloadOutlined, DeleteFilled,
    AreaChartOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined
} from '@ant-design/icons';
import { BrowserRouter as Router } from "react-router-dom";
import { Modal, Row, Col, Input, Form, Button, Popconfirm, message, Table, Popover, Tabs, Tooltip } from 'antd';
import { Breadcrumb, DatePicker } from 'antd';
import moment from 'moment';
import DataGA from './components/DataGA';
import ChartWeeklyCategory from './components/ChartWeeklyCategory';
import ChartWeeklyReviews from './components/ChartWeeklyReviews';
import ChartWeeklyKeyword from './components/ChartWeeklyKeyword';
import ChartWeeklyRating from './components/ChartWeeklyRating';
import ModalCompetitor from '../modal/ModalCompetitor';
import { colors } from '../../utils/colors';
import ChartChangeLog from './components/ChartChangeLog';
const { TabPane } = Tabs;

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

function DetailApp(props) {
    const idDetail = props.match.params.id;

    const [keywordPosition, setKeywordPosition] = useState([]);
    const [dataKeywordsShow, setDataKeywordsShow] = useState([]);
    const [dataChartChangeLog, setDataChartChangeLog] = useState([]);
    const [isEditKeyword, setIsEditKeyword] = useState(false);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [hasGoogleToken, setHasGoogleToken] = useState();
    const [data, setData] = useState();
    const [countKeyword, setCountKeyword] = useState(0);
    const [isVisibleCompetitor, setIsVisibleCompetitor] = useState(false);
    const [competitor, setCompetitor] = useState();
    const [activeState, setActiveState] = useState(1);
    const [id, setId] = useState(idDetail)

    const [dataAllTab, setDataAllTab] = useState([]);

    const keyName = useRef("");
    const viewID = useRef("");
    const [form] = Form.useForm();

    const getLink = "https://apps.shopify.com/" + id;
    const getTitle = "apps.shopify.com/" + id;

    useEffect(() => {
        if (id) {
            const error = new URLSearchParams(window.location.search).get("error");
            if (error) {
                message.error("Sync google analytics error")
            }
            asyncFetch(id, fromDate, toDate);
        }
    }, []);

    const asyncFetch = async (id, fromDate, toDate) => {
        const result = await FetchApi.getDetailApp(id, null, fromDate, toDate);
        if (result && result.code == 0) {
            viewID.current = result.view_id;
            const dataTabNew = [{
                id: idDetail,
                value: result
            }];
            setDataAllTab(dataTabNew);
            setKeywordPosition(result.data.keyword_pos);
            setDataChartChangeLog(convetDataChartChangeLog(result.data.change_log))
            setFromDate(result.start_date);
            setToDate(result.end_date);
            setHasGoogleToken(result.has_google_token);
            setData(result.data);

            let count = 0;
            result.data.keyword_pos.map(item => {
                if (!item.show) {
                    count++;
                }
            })
            setCountKeyword(count);
            setDataKeywordsShow(dataKeywords(result.data.keyword_pos));

            const panes = [{ appId: id, title: result.data.detail.name, content: <></>, key: '1', closable: false }]
            if (result.competitor) {
                result.competitor.map((item) => {
                    const activeKey = (panes && panes.length ? +panes[panes.length - 1].key : 0) + 1;
                    panes.push({ appId: item.app_id, title: item.name[0], content: <></>, key: activeKey })
                });
            }
            setActiveState(panes[0].key)
            setCompetitor(panes);

        } else {
            message.warn(result.messsage)
        }
    };

    const handleEditOk = () => {
        setIsEditKeyword(true);
    };

    const handleEditCancel = () => {
        setIsEditKeyword(false);
    };

    const createData = (dataPosition) => {
        const labels = [];
        const allTypes = [];
        const datasets = [];
        const dateValue = {};
        const datapoints = {};
        dataPosition.map((item) => {
            if (!labels.includes(item.date)) {
                labels.push(item.date);
            }
            if (!allTypes.includes(item.type)) {
                allTypes.push(item.type);
                datapoints[item.type] = [];
            }
            dateValue[item.date + '_' + item.type] = item.value;
        })
        labels.sort();
        labels.map((item) => {
            allTypes.forEach(function (val, key) {
                datapoints[val].push(dateValue.hasOwnProperty(item + '_' + val) ? dateValue[item + '_' + val] : NaN);
            })
        });
        allTypes.map((item, index) => {
            datasets.push({
                label: item,
                data: datapoints[item],
                borderColor: colors[index],
                backgroundColor: colors[index],
                fill: false,
                cubicInterpolationMode: 'monotone',
                tension: 0.4
            });

        });
        return {
            labels: labels,
            datasets: datasets,
        };
    }
    const convertDataFeatures = (data) => {
        let stringDataFeatures = "";
        if (data) {
            data.map((item) => {
                stringDataFeatures += item.description ? item.description + "\n" : "";
                stringDataFeatures += item.img ? item.img + "\n" : "";
                stringDataFeatures += item.title ? item.title + "\n\n" : "";
            });
        }
        return stringDataFeatures;
    }
    const convertDataPricePlaning = (data) => {
        let stringDataPricePlaning = "";
        if (data) {
            data.map((item) => {
                stringDataPricePlaning += item.alt ? item.alt + "\n" : "";
                stringDataPricePlaning += item.desc ? item.desc + "\n" : "";
                stringDataPricePlaning += item.pricing ? item.pricing + "\n" : "";
                stringDataPricePlaning += item.title ? item.title + "\n\n" : "";
            });
        }
        return stringDataPricePlaning
    }

    const createDataFeaturesEqual = (data) => {
        return {
            after: convertDataFeatures(data.after),
            before: convertDataFeatures(data.before)
        }
    }
    const createDataPricingPlaningEqual = (data) => {
        return {
            after: convertDataPricePlaning(data.after),
            before: convertDataPricePlaning(data.before)
        }
    }

    const convetDataChartChangeLog = (changeLog) => {
        const dataValue = [];
        changeLog.map((item) => {
            if (item.app_icon) {
                dataValue.push({
                    x: item.date,
                    y: 1,
                    data: item.app_icon,
                    type: 'app_icon'
                });
            }

            if (item.video) {
                dataValue.push({
                    x: item.date,
                    y: 2,
                    data: item.video,
                    type: 'video'
                });
            }
            if (item.description) {
                dataValue.push({
                    x: item.date,
                    y: 3,
                    data: item.description,
                    type: 'description'
                });
            }
            if (item.pricing_plan) {
                dataValue.push({
                    x: item.date,
                    y: 4,
                    data: createDataPricingPlaningEqual(item.pricing_plan),
                    type: 'pricing_plan'
                });
            }
            if (item.pricing) {
                dataValue.push({
                    x: item.date,
                    y: 5,
                    data: item.pricing,
                    type: 'pricing'
                });
            }
            if (item.title) {
                dataValue.push({
                    x: item.date,
                    y: 6,
                    data: item.title,
                    type: 'title'
                });
            }
            if (item.features) {
                dataValue.push({
                    x: item.date,
                    y: 7,
                    data: createDataFeaturesEqual(item.features),
                    type: 'features'
                });
            }
            if (item.tagline) {
                dataValue.push({
                    x: item.date,
                    y: 8,
                    data: item.tagline,
                    type: 'tagline'
                });
            }

            if (item.name) {
                dataValue.push({
                    x: item.date,
                    y: 9,
                    data: item.name,
                    type: 'name'
                });
            }


        });

        const datasets = [{
            label: "Change log",
            data: dataValue,
            fill: false,
            pointRadius: 5,
            backgroundColor: colors[0]
        }]
        return {
            datasets: datasets
        }
    }

    const addKeyword = () => {
        setIsEditKeyword(true)
    }
    const removeKeyword = (item) => async () => {
        if (item) {
            const result = await FetchApi.deleteKeyword(item.keyword, id);
            if (result.code === 0) {
                let dataNew = [...keywordPosition];
                const index = dataNew.findIndex(itemKeyword => item.keyword_slug === itemKeyword.keyword_slug)
                dataNew.splice(index, 1);
                setKeywordPosition(dataNew)
                message.success("Delete keyword success");
            } else {
                message.error("Delete keyword error");
            }
        }
    }
    const saveKeyword = async () => {
        const result = await FetchApi.createKeyword(form.getFieldValue("keyName"), id);
        if (result.code === 0) {
            keyName.current = "";
            form.resetFields();
            asyncFetch(id);
            setIsEditKeyword(false)
            message.success("Add keyword success");
        } else {
            message.error("Add keyword error");
        }
    }
    const reloadKeyword = () => async () => {
        const result = await FetchApi.reloadKeyword(id);
        if (result.code === 0) {
            message.success("Reload keyword success");
        } else {
            message.error("Reload keyword error");
        }
    }

    function onChangeDateRange(dates, dateStrings) {
        if (dateStrings) {
            setFromDate(dateStrings[0]);
            setToDate(dateStrings[1]);
        }
    }

    const searchByDate = () => {
        asyncFetch(id, fromDate, toDate);
    }

    const changeShowbadge = async (item) => {
        const result = await FetchApi.changeKeywordInChart(id, item.keyword, !item.show_in_chart)
        if (result && result.code == 0) {
            item.show_in_chart = !item.show_in_chart;
            let dataNew = [...keywordPosition];
            const index = keywordPosition.findIndex(i => i.keyword === item.keyword);
            dataNew[index] = item;
            setKeywordPosition(dataNew);
        }
    }

    const sorterColumn = (a, b) => {
        if (a === "-") {
            a = 0;
        }
        if (b === "-") {
            b = 0
        }
        return a - b;
    };

    const sorterColumnTime = (a, b) => {
        if (a === "-") {
            a = moment(0).format('mm:ss');
        }
        if (b === "-") {
            b = moment(0).format('mm:ss');
        }
        return moment.duration(a).asHours() - moment.duration(b).asHours();
    };

    const sorterColumnBounce = (a, b) => {
        a.replace('%', '');
        b.replace('%', '')
        if (a === "-") {
            a = 0;
        }
        if (b === "-") {
            b = 0;
        }
        return parseFloat(a) - parseFloat(b);
    }
    const columnsKeyword = [
        {
            title: 'Keywords',
            dataIndex: 'keyword',
            render: (item) => (
                <>
                    <a href={"/key/" + item.keyword_slug}>{item.keyword}</a>
                </>
            ),
        },
        {
            title: "Position",
            dataIndex: "rank",
            render: (item) => (
                <>
                    <div className="detail-position">
                        {item.rank}
                        {item.before_rank && item.rank && (item.before_rank - item.rank) > 0 ?
                            <span className="calular-incre"><UpOutlined /> {item.before_rank - item.rank} </span>
                            : ""}
                        {item.before_rank && item.rank && (item.before_rank - item.rank) < 0 ?
                            <span className="calular-decre"><DownOutlined /> {item.rank - item.before_rank} </span>
                            : ""}
                    </div>
                </>
            )
        },
        {
            title: 'Unique Pageviews',
            dataIndex: 'uniquePageviews',
            sorter: (a, b) => sorterColumn(a.uniquePageviews, b.uniquePageviews),
        },
        {
            title: 'Unique Events',
            dataIndex: 'uniqueEvents',
            sorter: (a, b) => sorterColumn(a.uniqueEvents, b.uniqueEvents),
        },
        {
            title: 'Avg Time on Page',
            dataIndex: 'avgTimeOnPage',
            sorter: (a, b) => sorterColumnTime(a.avgTimeOnPage, b.avgTimeOnPage),
        },
        {
            title: 'Bounce Rate',
            dataIndex: 'bounceRate',
            sorter: (a, b) => sorterColumnBounce(a.bounceRate, b.bounceRate),
        },
        {
            title: 'Avg Position',
            dataIndex: 'avgPos',
            sorter: (a, b) => sorterColumn(a.avgPos, b.avgPos),
        },
        {
            title: '',
            dataIndex: 'action',
            render: (item) => (
                <div className="action-table-keyword">
                    <div className="badge-keyword">
                        <AreaChartOutlined style={{ color: item.show_in_chart ? "#1A90FF" : "" }} onClick={() => changeShowbadge(item)} />
                    </div>
                    <div className="icon-action-keyword">
                        <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={removeKeyword(item)}>
                            <div className="remove">
                                <DeleteFilled />
                            </div>
                        </Popconfirm>
                    </div>

                    <div className="sort-list-key">
                        {
                            dataKeywordsShow.length === 1
                                ?
                                ""
                                :
                                getIndexKeywordsShow(dataKeywordsShow, item) === 0
                                    ?
                                    <div className="arrow-down-out" onClick={sortArrowDownOut(item)}>
                                        <ArrowDownOutlined />
                                    </div>
                                    : getIndexKeywordsShow(dataKeywordsShow, item) && getIndexKeywordsShow(dataKeywordsShow, item) === dataKeywordsShow.length - 1
                                        ?
                                        <div className="arrow-up_out" onClick={sortArrowUpOut(item)}>
                                            <ArrowUpOutlined />
                                        </div>
                                        :
                                        <>
                                            <div className="arrow-up_out" >
                                                <ArrowUpOutlined onClick={sortArrowUpOut(item)} />
                                            </div>
                                            <div className="arrow-down-out" >
                                                <ArrowDownOutlined onClick={sortArrowDownOut(item)} />
                                            </div>
                                        </>
                        }

                    </div>
                </div>
            ),
        }
    ];

    const getIndexKeywordsShow = (dataKeywordsShow, item) => {
        const index = dataKeywordsShow && dataKeywordsShow.findIndex(i => {
            return i.keyword.keyword == item.keyword;
        })
        return index;
    }

    const dataKeywords = (keywordPosition) => {
        const data = [];
        keywordPosition && keywordPosition.map((item, index) => {
            if (item.show) {
                data.push(
                    {
                        key: index,
                        keyword: item,
                        uniquePageviews: item.ga_keyword && (item.ga_keyword.uniquePageviews || item.ga_keyword.uniquePageviews === 0) ? item.ga_keyword.uniquePageviews : "-",
                        uniqueEvents: item.ga_keyword && (item.ga_keyword.uniqueEvents || item.ga_keyword.uniqueEvents === 0) ? item.ga_keyword.uniqueEvents : "-",
                        avgTimeOnPage: item.ga_keyword && (item.ga_keyword.avgTimeOnPage || item.ga_keyword.avgTimeOnPage === 0) ? moment(item.ga_keyword.avgTimeOnPage * 1000).format('mm:ss') : "-",
                        bounceRate: item.ga_keyword && (item.ga_keyword.bounceRate || item.ga_keyword.bounceRate === 0) ? item.ga_keyword.bounceRate.toFixed(2) + "%" : "-",
                        avgPos: item.ga_keyword && (item.ga_keyword.avgPos || item.ga_keyword.avgPos === 0) ? item.ga_keyword.avgPos.toFixed(2) : "-",
                        rank: item,
                        updateTime: item,
                        action: item,
                        priority: item.priority
                    }
                )
            }
        })
        return data;
    }

    const syncGoogleAlynatic = async () => {
        const result = await FetchApi.gaLogin(id);
        if (result && result.code == 0) {
            window.location.href = result.authorization_url;
        }
    }

    const disConnectGoogleAlynatic = async () => {
        const result = await FetchApi.gaDisconnect(id);
        if (result && result.code == 0) {
            window.location.reload();
        }
    }

    const sortArrowUpOut = (item) => async () => {
        const index = getIndexKeywordsShow(dataKeywordsShow, item);
        let dataNew = [...dataKeywordsShow];
        swap(dataNew, index, index - 1)
        var listKeword = [];
        dataNew.map((item) => {
            listKeword.push(item.keyword.keyword);
        })
        const result = await FetchApi.saveKeywordPriority(id, listKeword);
        if (result && result.code === 0) {
            // message.info("Swap item keyword success");
            setDataKeywordsShow(dataNew)
        } else {
            message.error("Error trying to save priority");
        }
    }

    const sortArrowDownOut = (item) => async () => {
        const index = getIndexKeywordsShow(dataKeywordsShow, item);
        let dataNew = [...dataKeywordsShow];
        swap(dataNew, index, index + 1)
        var listKeword = [];
        dataNew.map((item) => {
            listKeword.push(item.keyword.keyword);
        })
        const result = await FetchApi.saveKeywordPriority(id, listKeword);
        if (result && result.code === 0) {
            // message.info("Swap item keyword success");
            setDataKeywordsShow(dataNew)
        } else {
            message.error("Error trying to save priority");
        }
    }
    const swap = (input, index_A, index_B) => {
        let temp = input[index_A];
        input[index_A] = input[index_B];
        input[index_B] = temp;
        return input;
    }

    const disableModalCompetitor = () => {
        setIsVisibleCompetitor(false);
    };

    const addCompetitor = () => {
        setIsVisibleCompetitor(true);

    }

    const onChangeTab = (activeKey) => {
        fetchNewTab(activeKey);
    };

    const fetchNewTab = async (activeKey) => {
        const index = competitor.findIndex((item) => +item.key === +activeKey);
        const dataTab = competitor[index];
        setId(dataTab.appId);
        const indexTab = dataAllTab.findIndex((item) => {
            return item.id === dataTab.appId
        })
        const resultTab = {};
        if (indexTab !== -1) {
            Object.assign(resultTab, dataAllTab[index].value);
        } else {
            const result = await FetchApi.getDetailApp(dataTab.appId, competitor[0].appId, fromDate, toDate);
            if (result && result.code === 0) {
                const resultTabNew = [...dataAllTab];
                resultTabNew.push({
                    id: dataTab.appId,
                    value: result
                })
                setDataAllTab(resultTabNew);
                Object.assign(resultTab, result)
            }
        }
        if (resultTab) {
            setKeywordPosition(resultTab.data.keyword_pos)
            setDataChartChangeLog(convetDataChartChangeLog(resultTab.data.change_log))
            setFromDate(resultTab.start_date);
            setToDate(resultTab.end_date);
            setHasGoogleToken(resultTab.has_google_token);
            setData(resultTab.data);

            let count = 0;
            resultTab.data.keyword_pos.map(item => {
                if (!item.show) {
                    count++;
                }
            })
            setCountKeyword(count);
            setDataKeywordsShow(dataKeywords(resultTab.data.keyword_pos));
            viewID.current = resultTab.view_id;
        }
        setActiveState(activeKey);
    }

    const onEditTab = (targetKey, action) => {
        if (action === "remove") {
            removeTab(targetKey);
        }
    };

    const removeTab = async (key) => {
        const index = competitor.findIndex((item) => +item.key === +key);
        const dataTab = competitor[index];
        const result = await FetchApi.deleteCompetitor(idDetail, dataTab.appId);
        if (result && result.code === 0) {
            setCompetitor((prev) => {
                const idx = prev.findIndex((item) => +item.key === +key);
                prev.splice(idx, 1);
                return [...prev];
            });
            if (activeState === key) {
                setActiveState(competitor[0].key);
                fetchNewTab(competitor[0].key);
            } else {

            }
            message.info("Delete comeptitor success!")
        }
    };

    const dataTabNew = (item) => {
        const activeKey = (competitor && competitor.length ? +competitor[competitor.length - 1].key : 0) + 1;
        setCompetitor((prev) => [
            ...prev,
            { appId: item.app_id, title: item.name, content: <></>, key: activeKey }
        ]);
    }

    return (
        <Router>
            <div className="detail-app">
                <div className="popup-add-competitor">
                    {isVisibleCompetitor
                        &&
                        <ModalCompetitor
                            appId={id}
                            disableModal={disableModalCompetitor}
                            dataTabNew={dataTabNew}
                            competitor={competitor}
                        ></ModalCompetitor>
                    }
                </div>
                <div className="popup-change-keyword">
                    <Modal width={380} title="Add Keyword" footer={null} visible={isEditKeyword} onOk={handleEditOk} onCancel={handleEditCancel}>
                        <div className="popup-edit-keyword">
                            <div className="input-keyword">
                                <Form form={form} layout="vertical" className="form-edit">
                                    <Form.Item
                                        label="Keyword"
                                        name="keyName"
                                        required
                                        initialValue={keyName.current}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter keyword",
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="form-input"
                                            placeholder="keyword"
                                            style={{ width: 320 }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className="button-edit"
                                            type="primary"
                                            onClick={saveKeyword}
                                            style={{ width: 320 }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </div>
                        </div>
                    </Modal>
                </div>

                <div className="detail-app-header">
                    <Breadcrumb>
                        <Breadcrumb.Item className="link">
                            <a href={"/categories"}>Categories</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className="link">
                            <a href={"/category/all"}>All</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className="link">
                            {data && data.detail.name}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="competitor">
                    <Tabs
                        type="editable-card"
                        addIcon={
                            <div className="add-competitor">
                                <Button type="dashed" icon={<PlusOutlined />} size="large" onClick={addCompetitor}>
                                    Add competitor
                                </Button>
                            </div>
                        }
                        onChange={onChangeTab}
                        activeKey={activeState}
                        onEdit={onEditTab}
                    >
                        {competitor && competitor.map(pane => (
                            <TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
                                {pane.content}
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
                <div className="header-detail-app-info">
                    <div className="header-detail-app-info-left">
                        <div className="detail-app">
                            <div className="detail-app-info">
                                <div className="image">
                                    <img src={data && data.detail.app_icon}></img>
                                </div>
                                <div className="title-app">
                                    <div className="name">{data && data.detail.name}</div>
                                    <div className="tagline">{data && data.detail.tagline}</div>
                                    <div className="by"><span>by </span>
                                        <a href={data && data.detail.partner ? data && "/developer/" + data.detail.partner.id : "#"}>{data && data.detail.partner ? data && data.detail.partner.name : ""}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="view-on-shopify">
                            <span>View on Shopify: <a href={getLink} target="blank">{getTitle}</a></span>
                        </div>
                        <div className="rating">
                            <span>Rating </span><span className="star"><StarFilled /> {data && data.detail.star}</span> | {data && data.detail.review_count} reviews
                        </div>
                    </div>
                    <div className="header-detail-app-info-right">
                        <div className="sync-google">
                            {hasGoogleToken == 0 ?
                                <Button type="primary" size={'large'} onClick={syncGoogleAlynatic}>
                                    Sync Google Analytics
                                </Button>
                                : hasGoogleToken == 1
                                    ?
                                    <Button className="button-waiting-connect" type="primary" size={'large'}>
                                        Waiting connect GA
                                    </Button>
                                    : hasGoogleToken == 2
                                        ?
                                        viewID.current && viewID.current != "" ?

                                            <Tooltip placement="top" title={`Disconnection GA view id ` + viewID.current}>
                                                <Button type="primary" size={'large'} danger onClick={disConnectGoogleAlynatic}>
                                                    Disconnect GA
                                                </Button>
                                            </Tooltip>
                                            : 
                                            <Button type="primary" size={'large'} danger onClick={disConnectGoogleAlynatic}>
                                                Disconnect GA
                                            </Button>
                                        : ""
                            }
                        </div>
                    </div>

                </div>
                <div className="date-range">
                    <span className="title-name">Date Range: </span>
                    {fromDate && toDate ?
                        <div>
                            <RangePicker
                                defaultValue={[moment(fromDate, dateFormat), moment(toDate, dateFormat)]}
                                format={dateFormat}
                                allowClear={false}
                                onChange={onChangeDateRange}
                            />
                            <SearchOutlined className="icon-search-date" onClick={searchByDate} />
                        </div>

                        : ""}

                </div>
                <div className="body-detail-app">

                    <div className="table-categories-position">
                        <div className="title-cate">
                            Category Positions
                        </div>
                        {data && data.cat_pos.map((item, index) => {
                            return (
                                <div className="item-cate" key={"" + index} style={{ backgroundColor: index % 2 != 0 ? '#F2F2F2' : "" }}>
                                    <Row gutter={[24, 12]}>
                                        <Col className="gutter-row" span={12} >
                                            <div>
                                                <a href={"/category/" + item.cat_parent_slug}>{item.cat_parent}</a>
                                                {item.cat_parent ? <span>{'->'}</span> : ""}
                                                <a href={"/category/" + item.cat_slug}>{item.cat}</a>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" span={8} >
                                            <div>
                                                {item.rank} / {item.cat_app_count}
                                                {item.before_rank && item.rank && (item.before_rank - item.rank) > 0 ?
                                                    <span className="calular-incre"><UpOutlined /> {item.before_rank - item.rank} </span>
                                                    : ""}
                                                {item.before_rank && item.rank && (item.before_rank - item.rank) < 0 ?
                                                    <span className="calular-decre"><DownOutlined /> {item.rank - item.before_rank} </span>
                                                    : ""}
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" span={4} >
                                            <div>
                                                Page {item.page}
                                                {item.before_page && item.page && (item.before_page - item.page) > 0 ?
                                                    <span className="calular-incre"><UpOutlined /> {item.before_page - item.page} </span>
                                                    : ""}
                                                {item.before_page && item.page && (item.before_page - item.page) < 0 ?
                                                    <span className="calular-decre"><DownOutlined /> {item.page - item.before_page} </span>
                                                    : ""}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}

                    </div>
                    <div className="table-keyword-position">
                        <div className="header-table">
                            <div className="button-add">
                                <div className="reload" onClick={reloadKeyword()}>
                                    <ReloadOutlined />
                                </div>
                                <div>
                                    {activeState && activeState == 1 ?
                                        <div>
                                            <Button type="primary" onClick={addKeyword}>
                                                Add
                                            </Button>
                                        </div>
                                        : ''
                                    }
                                </div>

                            </div>
                        </div>
                        <Table columns={columnsKeyword} dataSource={dataKeywordsShow}
                            pagination={false}
                            scroll={{ y: 500 }}
                        />
                        {countKeyword > 0 ?
                            <div className="count-false">
                                <Popover content={
                                    <div className="list-key-hidden">
                                        {keywordPosition && keywordPosition.map((item, index) => {
                                            if (!item.show) {
                                                return (
                                                    <div className="item-key-hidden" key={"" + index}>
                                                        {item.keyword}
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                } title="" trigger="hover">
                                    Appear on <b>{countKeyword}</b> other keywords
                                </Popover>
                            </div>
                            : <div className="count-false">
                                Appear on <b>{countKeyword}</b> other keywords
                            </div>
                        }
                    </div>
                    <div className="chart-weekly-categories">
                        <ChartWeeklyCategory value={data && createData(data.position)}></ChartWeeklyCategory>
                    </div>
                    <div className="chart-weekly-keyword">
                        <ChartWeeklyKeyword value={data && createData(data.keyword_position)}></ChartWeeklyKeyword>
                    </div>
                    <div className="chart-weekly-reviews">
                        <ChartWeeklyReviews value={data && createData(data.review)}></ChartWeeklyReviews>
                    </div>
                    <div className="chart-weekly-rating">
                        <ChartWeeklyRating value={data && createData(data.star)}></ChartWeeklyRating>
                    </div>
                    <div id="chart-log-weekly" className="chart-weekly-change">
                        <ChartChangeLog value={dataChartChangeLog}></ChartChangeLog>
                    </div>
                    <div className="data-from-ga">
                        <DataGA value={data && data.ga_data}></DataGA>
                    </div>
                </div>
            </div>
        </Router >
    )
}
export default DetailApp;