import "./App.css";
import { ReactQueryConfigProvider } from "react-query";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Main from "./containers/Main";
import React from "react";
import Login from "./views/auth/Login";
import ChangePassword from "./views/auth/change_pass/ChangePassword";
import LoginGoogle from "./views/auth/LoginGoogle";
import SyncGoogleGa from "./views/profile/SyncGoogleGa";
import { Footer } from "antd/lib/layout/layout";
import FooterSasi from "./containers/Footer";

import privacyPolicy from "./views/privacyPolicy/privacyPolicy";
import termsOfUse from "./views/termsOfUse/termsOfUse";
import aboutUs from "./views/aboutUs/aboutUs"; 

function App() {
  const queryConfig = { queries: { refetchOnWindowFocus: false } };
  return (
    // <ReactQueryConfigProvider config={queryConfig}>
    <div className="App">
      <Router>
        <Switch>
        {/* <Route path="/login" component={Login} /> */}
        {/* <Route path="/google_login_callback" component={LoginGoogle} />
          <Route path="/ga_callback" component={SyncGoogleGa} />
          <Route path="/change-password" component={ChangePassword} /> */}
        {/* <Route path="privacy-policy" component={privacyPolicy} />
        <Route path={`${REACT_APP_BUILD_PATH}/about-us`} component={aboutUs} />
        <Route path={`${REACT_APP_BUILD_PATH}/terms-of-use`} component={termsOfUse} /> */}
        {/* <Route path="/google_login_callback" component={LoginGoogle} /> */}
        <Route path="/" component={Main} />
        </Switch>
      </Router>
    </div>
    // </ReactQueryConfigProvider>
  );
}

export default App;
